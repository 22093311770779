import React from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import ImageLink from "../../../../core/Components/ImageLink";
import Link from "../../../../core/Components/Link";
import { withHeaderContext } from "../../../../HeaderContext";

const CompatibilityFooter = ({ fields, headerContext }) => (
  <footer
    className={`global-footer-2${headerContext?.showMobileMenu ? " is-hidden" : ""}`}
  >
    <div className="l-padding">
      <div className="footerContainer">
        <div className="footerLogo">
          <Image field={fields.logo} />
        </div>
        <div className="navLinks">
          <div className="navLinksColumn">
            {fields.navigationLinks.slice(0, 3).map((links) => (
              <Link
                className="navlink"
                key={links.id}
                field={links.fields.link}
              />
            ))}
          </div>
          <div className="navLinksColumn">
            {fields.navigationLinks.slice(3, 5).map((links) => (
              <Link
                className="navlink"
                key={links.id}
                field={links.fields.link}
              />
            ))}
          </div>
          <div className="navLinksColumn">
            {fields.navigationLinks.slice(5, 7).map((links) => (
              <Link
                className="navlink"
                key={links.id}
                field={links.fields.link}
              />
            ))}
          </div>
        </div>
        <div className="socialIcons">
          {fields.socialLinks.map((links) => (
            <ImageLink
              imageField={links.fields.image}
              linkField={links.fields.link}
            />
          ))}
        </div>
      </div>
      <div className="subfooter-container">
        <div className="subfooter-copyright">
          <RichText field={fields.copyrightText} />
        </div>
      </div>
    </div>
  </footer>
);

export default withHeaderContext(CompatibilityFooter);
